import { Platform } from 'react-native';
import 'es6-promise';
import '@babel/polyfill';
import 'isomorphic-fetch';

if (Platform.OS === 'web') {
  import('./index.web').then(indexWeb => {
    indexWeb.IndexWeb();
  });
} else if (Platform.OS === 'ios' || Platform.OS === 'android') {
  import('./index.mobile').then(indexMobile => {
    indexMobile.IndexMobile();
  });
}
